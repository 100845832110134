.Card {
  background: white;
  padding: 10px;
  margin: 25px 5px 0px 5px;
  box-shadow: none !important;
  border-radius: 0px !important;
}

.Card:hover {
  cursor: pointer;
}

.Card p {
  padding-top: 5px;
  line-height: 25px;
}

h3,
h2,
h6 {
  text-align: center;
  margin: 0;
}

h3 {
  font-size: 18px;
}
