.photo {
  width: 100px;
  border-radius: 50%;
  float: right;
  overflow: auto;
  margin: 0;
  border: 5px solid white;
}

.contenedorDatosCuriosos {
  border: 3px solid #d1c8c1;
  padding: 20px;
  margin-top: 30px;
}

.sectionExperience {
  max-width: 80%;
  margin: 0 auto;
}
