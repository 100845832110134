#education {
  background-color: #e3dfdb;
  padding: 20px 0px;
}
.sectionEducation {
  max-width: 80%;
  margin: auto;
  color: #5e5343;
}
.Education p {
  text-align: center;

  border-radius: 20px;
  border: 1px solid grey;
  font-size: 17px;
  padding: 50px;
  border-radius: 20px;
  margin-top: 50px;
  margin-bottom: 20px;
}

@media (max-width: 480px) {
  .Education p {
    font-size: 14px;
    padding: 15px;
    border-radius: 20px;
    margin: 8px;
  }
  .contenedorEducacion {
    padding: 0px !important;
  }
  .columnaEducacion {
    margin: 20px 10px !important;
  }
  .columnaEducacion h1 {
    text-align: left !important;
  }
}

.contenedorEducacion {
  padding: 20px 150px 50px 150px;
}

.columnaEducacion {
  border-left: 3px solid #d1c8c1;
  margin: 0px 10px;
}
.columnaEducacion h1 {
  text-align: center;
}
