.ContactMe {
  background-color: #d9e9e8;

  padding: 350px 280px 10px;
}
.ContactMe p {
  text-align: center;
}

.Footer {
  list-style: none;
  padding-right: 10px;
  display: flex;
  justify-content: center;
  padding: 10px 20px 10px 10px;
  padding-left: 0px;
}

.button {
  font-size: 20px;
  margin-right: 20px;
  letter-spacing: 1px;
  margin-top: 8px;
  text-align: center;
}
.button a {
  color: #5e5343;
}

.buttonEmail:hover {
  cursor: pointer;
  color: #f0be15;
}
li:last-of-type {
  margin-top: 8px;
}

.Author p {
  font-size: 12px;
}

.Author {
  margin-top: 50px;
  margin-bottom: 200px;
}

@media (max-width: 480px) {
  .ContactMe {
    padding: 200px 0px 100px 0px;
  }
  .Author {
    margin-top: 0px;
  }

  .ContactMe h2 {
    max-width: 70%;
    display: flex;
    justify-content: center;
    margin: auto;
  }
  .ContactMe hr {
    max-width: 70%;
    display: flex;
    justify-content: center;
    margin: 0px auto 50px;
  }
  .ContactMe p {
    text-align: center;
    font-size: 12px;
  }
  .Author p {
    font-size: 10px;
  }

  .Footer {
    list-style: none;
    padding: 0;
    display: block;
    text-align: center;
  }

  .Footer li {
    margin: 0;
    line-height: 50px;
  }
}
