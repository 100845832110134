.AboutMe {
  max-width: 80%;
  margin: 0 auto;
  padding: 15px;
  border-radius: 20px;
}

.Description {
  margin: 10px;
}

.Description h2 {
  text-align: center;
}
.Description p {
  font-size: 17px;
  text-align: center;
  box-shadow: 5px 4px 5px 0px rgba(140, 138, 138, 0.35);
  -webkit-box-shadow: 5px 4px 5px 0px rgba(140, 138, 138, 0.35);
  -moz-box-shadow: 5px 4px 5px 0px rgba(140, 138, 138, 0.35);
  background-color: white;
  padding: 50px;
  border-radius: 200px;
}

@media (max-width: 480px) {
  .Description p {
    font-size: 14px;
    padding: 15px;
    border-radius: 20px;
  }
}
.descriptionThingAboutMe {
  text-align: center;
  border: 3px solid #d1c8c1;
  padding: 100px 10px 0px 10px;
  margin: 10px 20px;
}
