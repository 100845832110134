@media (max-width: 480px) {
  .Hobbies {
    max-width: 80%;
    margin: 50px auto;
  }
  .imagenParaHobbie {
    width: 300px;
    height: 400px;
    margin-bottom: 10px;
  }
  .imagenParaHobbie img {
    width: 100% !important;
  }
}
.Hobbies {
  max-width: 70%;
  margin: auto;
  margin-bottom: 20px;
}

.imagenParaHobbie {
  height: 300px;
}
.imagenParaHobbie img {
  width: -webkit-fill-available;
}

.imagenParaHobbie_viajar,
.imagenParaHobbie_fotografia,
.imagenParaHobbie_comida,
.imagenParaHobbie_perro {
  width: 100%;
  height: 300px;
  margin: 5px 0 5px;
  background-position: center;
  background-size: cover;
}

.imagenParaHobbie_viajar {
  background-image: url("/public/images/imagenViajar.jpg");
}
.imagenParaHobbie_fotografia {
  background-image: url("/public/images/fotografia.jpg");
}
.imagenParaHobbie_comida {
  background-image: url("/public/images/imagenPastas.jpg");
}

.imagenParaHobbie_perro {
  background-image: url("/public/images/imagenPerros.jpg");
}
