@media (max-width: 480px) {
  .contenedorBienvenida {
    position: relative;
  }
  .contenedorTextoHeader {
    color: white;
    position: absolute;
    bottom: 60px;
    left: 10px;
  }
  .mensajeBienvenidaNombre {
    font-size: 25px !important;
    padding: 0px 28px 0px 5px;
    border-radius: 0px 20px 20px 0px;
    background-color: #d1c8c1 !important;
  }
  .mensajeBienvenidaPuesto {
    font-size: 10px;
    padding: 0px 28px 0px 5px;
    background-color: #d1c8c1 !important;
    border-radius: 0px 20px 20px 0px;
  }
  .mensajeBienvenidaDescripcion {
    font-size: 12px;
    font-style: italic;
    background-color: #d1c8c1 !important;
    padding: 0px 28px 0px 5px;
    border-radius: 0px 20px 20px 0px;
  }

  .botonContacto {
    border: 0px;
    font-size: 12px !important;
    padding: 5px 30px;
    border-radius: 20px;
    display: flex;
    margin: 20px auto 0px !important;
    color: white;
    background-color: #d1c8c1;
    text-align: end;
  }
}

.contenedorBienvenida {
  width: 100%;
  margin-top: 50px;
}

.imagenBienvenida {
  width: 100%;
  border-radius: 400px 0px 0px 400px;
}

.contenedorTextoHeader {
  margin-top: 30%;
  margin-right: 10px;
  font-family: "Quicksand", sans-serif;
  display: flex;
  flex-direction: column;
  text-align: end;
}
.mensajeBienvenidaNombre {
  font-size: 35px;
  font-weight: 800;
}
.mensajeBienvenidaPuesto {
  font-size: 20px;
  font-weight: 500;
}

.mensajeBienvenidaDescripcion {
  font-style: italic;
}

.botonContacto {
  border: 0px;
  font-size: 20px;
  padding: 5px 30px;
  border-radius: 20px;
  display: flex;
  margin: 50px auto 0px;
  color: white;
  background-color: #d1c8c1;
  text-align: end;
}
