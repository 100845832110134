.App {
  max-width: 100%;

  font-family: "Quicksand", sans-serif;
  width: 100%;
  margin: auto;
}
body {
  background: #efe9e4;
  color: #5e5343;
}
