.Skills {
  padding-top: 50px;
  margin: auto;
  max-width: 70%;
  min-height: 450px;
  margin-bottom: 50px;
}
.developerSk,
.softSk,
.LanguagesSk {
  border-left: 3px solid #d1c8c1;
  min-height: 450px;
  background-position: bottom;
  margin: 10px 10px;
  background-size: contain;
  background-repeat: no-repeat;
}
.developerSk {
  background-image: url("./img/tech.png");
}

.softSk {
  background-image: url("./img/soft.png");
}

.LanguagesSk {
  background-image: url("./img/language.png");
}
