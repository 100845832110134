.LandingPage {
  background: #d9e9e8;
}
.Name {
  font-weight: 700;
  font-size: 20px;
  color: #eee1d8;
}

.Name span:hover {
  cursor: pointer;
}
.Menu {
  color: #eee1d8;
  text-align: right;
  display: flex;
  justify-content: right;
  font-size: 15px;
}
.Menu li {
  margin-left: 15px;
  margin-top: 10px;
  letter-spacing: 1px;
}
.Menu li:hover {
  cursor: pointer;
  font-weight: 700;
}
.progress-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 10px;
  background: #df6730;
  transform-origin: 0%;
  z-index: 3;
}

.active {
  font-weight: bold;
  color: #eee1d8;
  border-radius: 8px;
}

@media (max-width: 480px) {
  .LandingPage {
    width: 100%;
    position: sticky;
  }

  .Name {
    font-weight: 700;
    font-size: 25px;
    text-align: center;
  }
}
