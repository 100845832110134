.Main {
  margin: auto;
}

a {
  text-decoration: none;
  color: black;
}

a:hover {
  color: #f0be15;
}
